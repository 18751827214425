<template>
<div>
<!-- 新增竞价求购 -->
  <el-dialog
  v-dialogDrag
    title="竞价详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
    class="addRegist"
  >
    <div>
      <el-row :gutter="20">
        <el-col :span="12" style="line-height:30px">
          <span>竞价编号:{{auctionEnrolmentDetails.auctionCode}}</span>
        </el-col>
        <el-col :span="12" style="line-height:30px">
          <span>报名人数:{{auctionEnrolmentDetails.ennum}}人</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-botttom:30px">
        <!-- <el-col :span="1">
          <i class="el-icon-alarm-clock" style="font-size:20px;color:black"></i>
        </el-col> -->
        <!-- <el-col :span="6" style="line-height:30px" v-if="auctionEnrolmentGetter.style==2">
          <span>距离结束:{{day}} 天{{hour}} 时{{min}} 分{{second}} 秒</span>
        </el-col> -->
        <el-col :span="22" class="countDown">
          <span>距离结束:<i :class="isZoom?'zoomFont':''">{{day}}</i> 天<i :class="isZoom?'zoomFont':''">{{hour}}</i> 时<i :class="isZoom?'zoomFont':''">{{min}} </i>分<i :class="isZoom?'zoomFont':''">{{second}} </i>秒</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:10px">
        <el-col :span="4">
          <span>商品分类</span>
        </el-col>
        <el-col :span="4">
          <span>仓库</span>
        </el-col>
        <el-col :span="3">
          <span>重量</span>
        </el-col>
         <el-col :span="3">
          <span>起拍价</span>
        </el-col>
        <el-col :span="4" v-if="auctionEnrolmentGetter.style!=3">
          <span>当前价</span>
        </el-col>
        <el-col :span="4" v-if="auctionEnrolmentGetter.style==1">
          <span>最新出价方</span>
        </el-col>
        <el-col :span="2" v-if="auctionEnrolmentGetter.style==1">
          <!-- 如果是大宗竞拍不能看到出价方和别人的报价 -->
            <el-button
                v-if="auctionEnrolmentDetails.offerFirmId"
                @click.native.prevent="viewFiveDetail()"
                type="waring"
                size="small">
                查看
              </el-button>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:10px">
        <el-col :span="4">
          <span><div class="grid-content">{{auctionEnrolmentGetter.className}}</div></span>
        </el-col>
        <el-col :span="4">
          <span><div class="grid-content">{{auctionEnrolmentGetter.name}}</div></span>
        </el-col>
        <el-col :span="3">
          <span><div class="grid-content">{{auctionEnrolmentGetter.hangWeight}}{{auctionEnrolmentGetter.unit}}</div></span>
        </el-col>
           <el-col :span="3">
          <span><div class="grid-content">{{auctionEnrolmentGetter.startPrice}}</div></span>
        </el-col>
        <el-col :span="4" v-if="auctionEnrolmentGetter.style==1">
          <span><div class="grid-content">{{auctionEnrolmentDetails.offerPrice}}</div></span>
        </el-col>
        <el-col :span="4" v-if="auctionEnrolmentGetter.style==2">
          <span><div class="grid-content">{{currentOfferPrice}}</div></span>
        </el-col>
        <el-col :span="4" v-if="auctionEnrolmentGetter.style==1">
          <span><div class="grid-content">{{ auctionEnrolmentDetails.offerFirmId }}</div></span>
        </el-col>
         <el-col :span="2" v-if="auctionEnrolmentGetter.style==1">
         <el-button
             style="margin-top:5px;"  
            @click.native.prevent="paranm_queryAuctionEnrolmentDetails()"
            type="danger"
            size="small">
            刷新
          </el-button>
        </el-col>
      </el-row>
      <el-form ref="form" :rules="rules" :model="form" label-position="top">
        <el-row :gutter="20">
          <!-- 大宗竞拍不展示加价幅度 -->
          <el-col :span="12" v-if="auctionEnrolmentGetter.style==1&&auctionEnrolmentDetails.tradeWay!='P'">
            <el-form-item label="加价幅度" prop="addPrice">
              <el-select class="width-100" v-model="form.addPrice" placeholder="选择加价幅度">
                <el-option
                  v-for="item in auctionRule"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="auctionEnrolmentGetter.style==1&&auctionEnrolmentDetails.tradeWay=='P'">
            <el-form-item label="摘单重量" prop="minPickingWeight">
              <el-input v-model="form.minPickingWeight" :placeholder="'最小摘单重量是'+auctionEnrolmentDetails.minPickingWeight+auctionEnrolmentGetter.unit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="auctionEnrolmentGetter.style!=2">
            <el-form-item label="我的报价" prop="price">
              <el-input v-model="form.price"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取 消</el-button>
      <el-button v-if="auctionEnrolmentGetter.style!=2" type="primary" @click="confirm()" :loading="isAddLoading">竞 价</el-button>
      <el-button v-if="auctionEnrolmentGetter.style==2"  type="primary" @click="QingRow()" :loading="isAddLoading">抢 拍</el-button>
    </span>
       <div
       v-show="auctionEnrolmentGetter.style==2"
        class="commodityChartBox"
        ref="commodityChartsBox"
        style="width: 160px;height: 160px"
      ></div>

  </el-dialog>
   <el-dialog
     v-dialogDrag
    title="出价记录"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible1"
    width="30%"
   >
      <el-row style="text-align:center;">
          <el-col :span="12" style="font-size:16px">
            出价方
          </el-col>
          <el-col :span="12" style="font-size:16px">
            出价价格
          </el-col>  
      </el-row> 
       <!-- <div v-if="fiveDetails.length!=0"> -->
          <el-row v-for="(item,index) of fiveDetails" :key="index"  style="text-align:center;">
          <el-col :span="12">
            {{ item.offerFirmId }}
          </el-col>
          <el-col :span="12">
            {{ item.offerPrice }}
          </el-col> 
      </el-row>
      <!-- </div> -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible1=false">取 消</el-button>
      <el-button type="primary"  @click="dialogVisible1=false">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import echarts from "echarts";
export default {
  data() {
    var checkPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('报价不能为空'));
      }
      if(this.auctionEnrolmentGetter.style==1&&this.auctionEnrolmentDetails.tradeWay=='P'){
        let newPrice = this.auctionEnrolmentDetails.newStartPrice
        if(this.auctionEnrolmentGetter.style==3){
          return callback()
        }
        if (value <= Number(newPrice)) {
          return callback(new Error('报价请高于起拍单价'))
        }else{
          return callback()
        }
      }else{
        let newPrice = this.auctionEnrolmentDetails.offerPrice?this.auctionEnrolmentDetails.offerPrice:this.auctionEnrolmentGetter.startPrice
        if(this.auctionEnrolmentGetter.style==3){
            return callback()
        }
        if (value <= Number(newPrice)) {
          return callback(new Error('报价请高于当前价'))
        } else {
          let num = Number(value) - Number(newPrice)
          let isRight = false
          for (let i = 0; i < this.auctionRule.length; i++) {
            const element = this.auctionRule[i];
            if(num%Number(element)==0){
              isRight = true
            }
          }
          if (isRight) {
            return callback()
          } else {
            return callback(new Error('报价不符合加价幅度整数倍要求'))
          }
        }
      }
    };
    var checkPickWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (this.auctionEnrolmentGetter.style==1&&this.auctionEnrolmentDetails.tradeWay=='P' && !value) {
        return callback(new Error('摘单重量不能为空'));
      } else if(value){
        if (!reg.test(value) || Number(value) <= 0) {
          return callback(new Error("最小摘单重量必须是正数且不能超过两位小数"));
        } else if (Number(value) > Number(this.auctionEnrolmentDetails.hangWeight)) {
          return callback(new Error("摘单重量必须小于等于挂单重量"));
        } else if (Number(value) < Number(this.auctionEnrolmentDetails.minPickingWeight)) {
          return callback(new Error("摘单重量必须是大于等于最小摘单重量"));
        }else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      dialogVisible1:false,
      dialogVisible: false,
      isZoom: false,
      form: {
        addPrice:'',// 加价幅度
        price:'', //我的报价
        minPickingWeight: ''// 摘单重量
      },
      rules: {
        addPrice: [
          { required: false, message: "请选择加价幅度", trigger: "change" }
        ],
        minPickingWeight: [
          { required: false, message: "请输入摘单重量", trigger: "change" },
          { validator: checkPickWeight, trigger: 'change' }
        ],// 摘单重量
        price: [
          { required: true, message: "请输入我的报价", trigger: "change" },
          { validator: checkPrice, trigger: 'blur' }
        ],// 我的报价
       
      },
      isAddLoading: false,
      auctionEnrolmentDetails:{},
      auctionRule:[],
      // 倒计时
      timeOut:null,
      day:0,
      hour:'00',
      min:'00',
      second:'00',
      fiveDetails: [],
      chart: null,
      option: {},
      currentOfferPrice: null,
      timeOfferTime: null,
      chartTime : null,
      detailTime: null,
      isFinish: false,
      offerPrices:null,
      isBian:2,// 表盘数值是否为0
      
    };
  },
  computed:{
    ...mapGetters(['auctionEnrolmentGetter']), // 列表数据
    finPrice(){
      return this.form.addPrice
    },

  },
  watch: {
    finPrice(val,oldVal){
      let newPrice = this.auctionEnrolmentDetails.offerPrice?this.auctionEnrolmentDetails.offerPrice:this.auctionEnrolmentGetter.startPrice
      if(val&&this.auctionEnrolmentGetter.style==1){
        this.form.price = Number(val)+Number(newPrice)
      }
    },
    'auctionEnrolmentDetails.offerPrice': {
      handler(val, oldVal) {
      //let newPrice = this.auctionEnrolmentDetails.offerPrice?this.auctionEnrolmentDetails.offerPrice:this.auctionEnrolmentGetter.startPrice
        if(val&&this.auctionEnrolmentGetter.style==1){
          this.form.price = Number(this.form.addPrice)+Number(val)
        }
      }
    },
   
  },
  methods: {
    initChart(){
      let subTime = util.floatSub(this.auctionEnrolmentDetails.endTime,this.auctionEnrolmentDetails.startTime)
      let that = this
      this.option = {
            tooltip: {
                formatter: '{c}%'
            },
            series: [
              {
                name: '业务指标',
                type: 'gauge',
                radius: '100%',
                axisLine: {
                  lineStyle: {
                    width: 10 ,// 这个是修改宽度的属性
                    color: [[0.2, '#c23531'], [0.8, '#63869e'], [1, '#91c7ae']]
                  }
                },
                axisLabel: {            // 坐标轴小标记
                    textStyle: {       // 属性lineStyle控制线条样式
                        color: '#000',
                        fontSize:7,   //改变仪表盘内刻度数字的大小
                        shadowColor : '#000', //默认透明        
                }},
                splitLine: {
                  length: -15, // 属性length控制线长
                  lineStyle: {
                    // 属性lineStyle（详见lineStyle）控制线条样式
                    width: 1,
                    color: "#fff",
                  }
                },
                pointer: {
                  width:3,//指针的宽度
                  length:"90%", //指针长度，按照半圆半径的百分比
                  shadowColor : '#ccc', //默认透明
                  shadowBlur: 5
                  },
                detail: {
                  formatter:'{value}%',
                  textStyle: {
                    fontSize:30,
                  }
                },
                data: [{value: 100, name: ''}],     
              }
            ]
        };
        this.chart = echarts.init(that.$refs.commodityChartsBox);
        this.chartTime = setInterval(function () {
            const date = new Date()
            const now = date.getTime()
            let littleTime=util.floatSub(that.auctionEnrolmentDetails.endTime,now) //毫秒
            let selfTime = Number((Number(littleTime)/Number(subTime))*100).toFixed(0)
            that.option.series[0].data[0].value = selfTime;
            that.chart.setOption(that.option,true);
            console.log('selfTime')
            if(that.day==0&&that.hour=='00'&&that.min=='00'&&that.second=='00'){
             that.option.series[0].data[0].value = 0;
             that.isBian = 0
              that.chart.setOption(that.option,true);
              clearInterval(that.chartTime)
              that.chartTime = null
            }
        },1000);
    },
    calcOfferPrice(){
        let protectPrice = this.auctionEnrolmentDetails.protectPrice
        let saleSecond = Number(this.auctionEnrolmentDetails.saleSecond)*1000
       // let currentPrice = util.floatMul(this.auctionEnrolmentDetails.decreasePrice,saleSecond)
        let currentPrice = this.auctionEnrolmentDetails.decreasePrice
           // 没人抢拍的价格
        let noEndPrice = null
        let littleTime=util.floatSub(this.auctionEnrolmentDetails.endTime,this.auctionEnrolmentDetails.startTime)
        noEndPrice = (Number(this.auctionEnrolmentDetails.startPrice) - (Number(littleTime)/Number(saleSecond))*this.auctionEnrolmentDetails.decreasePrice).toFixed(2)
        const date = new Date()
        const now = date.getTime()
        if(now>this.auctionEnrolmentDetails.startTime&&now<this.auctionEnrolmentDetails.endTime){
          // 现在时间去除毫秒
          let nowHS = Number(now/1000).toFixed(0)
          let nowS = nowHS*1000
          let littleTime=util.floatSub(nowS,this.auctionEnrolmentDetails.startTime)
          this.currentOfferPrice = (Number(this.auctionEnrolmentDetails.startPrice) - (Number(littleTime)/Number(saleSecond))*this.auctionEnrolmentDetails.decreasePrice).toFixed(2)
        }
        let that = this
        this.timeOfferTime = setInterval(function(){
            that.currentOfferPrice = util.floatSub(that.currentOfferPrice,currentPrice)
            if(that.currentOfferPrice <= protectPrice||(that.day==0&&that.hour=='00'&&that.min=='00'&&that.second=='00'&&noEndPrice<=protectPrice)){
              that.currentOfferPrice  = protectPrice
               clearInterval(that.timeOfferTime)
              that.timeOfferTime = null
            }else if(that.day==0&&that.hour=='00'&&that.min=='00'&&that.second=='00'&&noEndPrice>protectPrice){
               that.currentOfferPrice  =  noEndPrice
               clearInterval(that.timeOfferTime)
              that.timeOfferTime = null
            }else  if(that.isFinish){
              that.currentOfferPrice  =  that.offerPrices
              clearInterval(that.timeOfferTime)
              that.timeOfferTime = null
            }
          },saleSecond)
    },
    timeClick(){
      if (this.auctionEnrolmentGetter&&this.auctionEnrolmentGetter.endTime) {
        this.countTime(this.auctionEnrolmentGetter.endTime)
        this.timeOut = setInterval(() => {
           if(this.isZoom){
             this.isZoom=false
           }else{
              this.isZoom=true
           }
          this.countTime(this.auctionEnrolmentGetter.endTime)
          if(this.day==0&&this.hour=='00'&&this.min=='00'&&this.second=='00'){
            clearInterval(this.timeOut)
            this.timeOut = null
          }
        }, 1000);
      }
    },
     QingRow() {
       if(this.day==0&&this.hour=='00'&&this.min=='00'&&this.second=='00'||this.offerPrices){
         this.$EL_MESSAGE('抢拍已结束');
          return
       }
      protocolNJP.param_offer.param.itemId = Number(this.auctionEnrolmentGetter.hangResourcesId);
      protocolNJP.param_offer.param.offerPrice = null;
      protocolNJP.param_offer.param.weight = this.form.minPickingWeight+'';
      http
        .postNewJP(protocolNJP.param_offer)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$EL_MESSAGE(message);
            this.handleClose();
          } else {
            this.$EL_MESSAGE(message);
          }
        })
        .catch(() => {
          this.isAddLoading = false;
        });
    },
    countTime(endPayTime) {
      // 获取当前时间
      const date = new Date()
      const now = date.getTime()
      // 设置截止时间
      // const endDate = new Date(endPayTime) // this.curStartTime需要倒计时的日期
      // const end = endDate.getTime()
      const end = Number(endPayTime)
      // 时间差
      const leftTime = end - now
      let day = ''
      let hour = ''
      let min = ''
      let second = ''
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        // 时
        let h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
        hour = h < 10 ? '0' + h : h
        // 分
        let m = Math.floor(leftTime / 1000 / 60 % 60)
        min = m < 10 ? '0' + m : m
        // 秒
        let s = Math.floor(leftTime / 1000 % 60)
        second = s < 10 ? '0' + s : s
      } else {
        day = 0
        hour = '00'
        min = '00'
        second = '00'
      }
      this.day = day
      this.hour = hour
      this.min = min
      this.second = second
    },
    showDialog(row) {
      this.dialogVisible = true;
      this.paranm_queryAuctionEnrolmentDetails()
      // this.param_queryAuctionRule()
      this.timeClick()   
    },
    // 详情
    paranm_queryAuctionEnrolmentDetails() {
      let _this = this
      protocolFwd.paranm_queryAuctionEnrolmentDetails.param = { hangResourcesId : this.auctionEnrolmentGetter.hangResourcesId }
      http.postFront(protocolFwd.paranm_queryAuctionEnrolmentDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
           if(value.tradeWay=='P'&&value.auctionStyle==1&& !value.offerPrice){
              value.offerPrice = util.floatDiv(value.startPrice, value.hangWeight) 
            }
            value.newStartPrice = util.floatDiv(value.startPrice, value.hangWeight)
          this.auctionEnrolmentDetails = value
          if(value.auctionStyle==2){
            if (value.isFinish == 1) {
              _this.isFinish = true
            }
              this.detailTime = setInterval(function(){
              _this.paranm_queryAuctionEnrolmentsDetails()
            
            },1000)
            if(value.offerPrice){
              this.currentOfferPrice = value.offerPrice
              this.offerPrices = value.offerPrice
            }else{
              this.currentOfferPrice = value.startPrice
              this.calcOfferPrice()
            }
            this.initChart()
            return
          }
          if(value.tradeWay!='P'&&value.auctionStyle==1){
            this.initAddPrice()
          }
          
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    }, 
    paranm_queryAuctionEnrolmentsDetails() {
      let _this = this
     protocolFwd.paranm_queryAuctionEnrolmentFiveDetails.param = { hangResourcesId : this.auctionEnrolmentGetter.hangResourcesId }
      http.postFront(protocolFwd.paranm_queryAuctionEnrolmentFiveDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
            if (value.content[0]!=null) {
              _this.offerPrices = value.content[0].offerPrice
              _this.isFinish = true
              clearInterval(_this.detailTime)
              _this.detailTime = null
            }else if(_this.isBian==0){
             _this.isFinish = true
              clearInterval(_this.detailTime)
              _this.detailTime = null

            }
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    }, 
    // // 加价幅度
    initAddPrice() {
      let arr = []
      let arrNum = 20
      for (let i = 0; i < arrNum; i++) {
        arr[i] = this.auctionEnrolmentDetails.addPrice*(i+1)
      }
      this.auctionRule = arr
    },
    // param_queryAuctionRule() {
    //   protocolFwd.param_queryAuctionRule.param = { commodityTypeId : this.auctionEnrolmentGetter.commodityTypeId }
    //   http.postFront(protocolFwd.param_queryAuctionRule).then(response => {
    //     const { code, message, value } = response.data;
    //     console.log(value)
    //     if (code == 0) {
    //       let arr = []
    //       arr[0] = value.addPriceOne
    //       arr[1] = value.addPriceTwo
    //       arr[2] = value.addPriceThree
    //       arr[3] = value.addPriceFour
    //       arr[4] = value.addPriceFive
    //       this.auctionRule = arr
    //     } else {
    //       this.$EL_MESSAGE(message)
    //     }
    //   })
    // },
      // 查询五次报价
    viewFiveDetail(){
      protocolFwd.paranm_queryAuctionEnrolmentFiveDetails.param = { hangResourcesId : this.auctionEnrolmentGetter.hangResourcesId }
      http.postFront(protocolFwd.paranm_queryAuctionEnrolmentFiveDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.fiveDetails = value.content
          this.dialogVisible1 = true
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    confirm() {
      let that = this
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolNJP.param_offer.param.itemId = Number(this.auctionEnrolmentGetter.hangResourcesId);
          protocolNJP.param_offer.param.offerPrice = this.form.price+'';
          protocolNJP.param_offer.param.weight = this.form.minPickingWeight+'';
          this.isAddLoading = true;
          http
            .postNewJP(protocolNJP.param_offer)
            .then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                // this.dialogVisible = false;
                this.$EL_MESSAGE(message);
                if(this.auctionEnrolmentGetter.style==3){
                  that.handleClose()
                  return
                }
                this.paranm_queryAuctionEnrolmentDetails()
                // this.$refs["form"].resetFields();
                // this.$emit("reLoad");
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.form = {
        addPrice:'',// 加价幅度
        price:'', //我的报价
        minPickingWeight: ''// 摘单重量
      };
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.dialogVisible = false;
      if(this.timeOut){
        clearInterval(this.timeOut)
        this.timeOut = null
      } 
      if(this.chartTime){
        clearInterval(this.chartTime)
        this.chartTime = null
      }
      if(this.timeOfferTime){
        clearInterval(this.timeOfferTime)
        this.timeOfferTime = null
      }
      if(this.detailTime){
        clearInterval(this.detailTime)
        this.detailTime = null
      }
    }
  }
};
</script>
<style scoped lang="scss">
.grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .commodityChartBox{
    position: absoute;
    left: 636px;
    top: -131px;
  }
  .countDown{
    line-height: 30px;
    position: relative;
    i{
      display: inline-block;
      text-align:center;
      width:40px;
      font-style: normal;
      color:#e1251b;
      font-size:15px;
    }
    .zoomFont{
       font-size:16px;
    }
  }
  // /deep/.el-col{
  //   padding:20px 10px;
  // }
</style>
<style >
.addRegist .el-dialog__body{
    padding: 15px 20px !important;
  }
</style>